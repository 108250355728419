import {
  List,
  Datagrid,
  TextField,
  EditButton,
  TextInput,
  BooleanInput,
  ReferenceInput,
  ReferenceArrayInput,
  TopToolbar,
  ListButton,
  RefreshButton,
  SelectInput,
  AutocompleteArrayInput,
  Edit,
  Create,
  CreateButton,
  SimpleForm,
  BulkDeleteWithUndoButton,
  DateInput,
  required,
  NumberField,
  ArrayInput,
  maxLength,
  Labeled,
  DateTimeInput,
} from 'react-admin';
import Typography from '@mui/material/Typography';
import { NumberInput } from '../../inputs/number-input';
import { AutocompleteInput } from '../../inputs/autocomplete-input';
import { SortingDirection } from '../../variables';
import { getIsDisabledByRole } from '../../utils/get-disabled-by-role';
import { AppResourceNames } from '../../variables/app-resource-names';
import { AdminDeleteButton } from '../../buttons/delete-btn';
import {
  activityStatusTypes,
  dateDisplayTypes,
  defaultDateDisplayType,
  defaultTaskStatusType,
  taskStatusTypes,
} from './constants';
import { ArrayInputIterator } from '../../inputs/array-input-iterator';
import { TinymceEditor } from '../../inputs/tinymce-editor';
import { Flex } from './styled';
import { ToggleButton } from '../../buttons/toggle-button';
import { TranslatableInput } from '../../inputs/translatable-input';

const EditActions = () => {
  return (
    <TopToolbar>
      <CreateButton />
      <ListButton />
      <RefreshButton />
    </TopToolbar>
  );
};

const Form = () => {
  return (
    <SimpleForm>
      <Flex>
        <ReferenceInput
          source="coin_id"
          filter={{ activeLifeCycle: true }}
          reference={AppResourceNames.COINS}
          sort={{ field: 'id', order: SortingDirection.ASC }}
        >
          <AutocompleteInput optionText="fullName" validate={required()} />
        </ReferenceInput>
        <BooleanInput source="enabled" />
      </Flex>

      <SelectInput source="status" choices={activityStatusTypes} validate={required()} />

      <Typography variant="h4">Rewards</Typography>

      <ReferenceInput
        source="reward_id"
        reference={AppResourceNames.DROP_HUNTING_REWARDS}
        sort={{ field: 'id', order: SortingDirection.ASC }}
      >
        <AutocompleteInput optionText="name" validate={required()} />
      </ReferenceInput>

      <Flex>
        <NumberInput source="rating_offset" defaultValue={0} validate={required()} />
        <Labeled flexDirection="row" gap={1} fontSize={20} position="relative" bottom={5}>
          <NumberField source="rating" label="Current rating" fontSize={18} />
        </Labeled>
        <BooleanInput source="sponsored" />
      </Flex>

      <Flex>
        <DateTimeInput source="distribute_at" defaultValue={null} />
        <SelectInput
          source="distribute_date_display"
          choices={dateDisplayTypes}
          defaultValue={defaultDateDisplayType}
          validate={required()}
          sx={{ width: 200 }}
        />
      </Flex>

      <Flex>
        <DateTimeInput source="distribute_end_at" defaultValue={null} />
        <SelectInput
          source="distribute_end_date_display"
          choices={dateDisplayTypes}
          defaultValue={defaultDateDisplayType}
          validate={required()}
          sx={{ width: 200 }}
        />
      </Flex>

      <TextInput source="check_link" sx={{ width: 400 }} />

      <Flex>
        <TextInput source="link_to_claim" sx={{ width: 400 }} />
        <BooleanInput source="activate_rewards" />
      </Flex>

      <NumberInput source="avg_reward_usd" label="Avg. Reward ($)" />

      <ArrayInput source="tasks" defaultValue={[]}>
        <ArrayInputIterator>
          <ReferenceInput
            source="type_id"
            reference={AppResourceNames.DROP_HUNTING_TASK_TYPES}
            sort={{ field: 'id', order: SortingDirection.ASC }}
          >
            <AutocompleteInput optionText="key" validate={required()} />
          </ReferenceInput>
          <ReferenceArrayInput
            source="blockchainIds"
            reference="token-platforms"
            sort={{ field: 'name', order: SortingDirection.ASC }}
          >
            <AutocompleteArrayInput optionText="name" label="Blockchains" />
          </ReferenceArrayInput>
          <DateInput source="start_at" defaultValue={null} sx={{ display: 'inline' }} />
          <DateInput source="end_at" defaultValue={null} sx={{ display: 'inline' }} />
          <SelectInput
            source="status"
            choices={taskStatusTypes}
            defaultValue={defaultTaskStatusType}
            validate={required()}
          />

          <NumberInput source="cost" label="Cost ($)" />
          <NumberInput source="time" label="Time (min)" />
          <BooleanInput
            source="is_pinned"
            label="Pin Task"
            helperText="If enabled, this task will be pinned on the top of the activity page."
          />
          <BooleanInput source="exclusive" defaultValue={false} />

          <TranslatableInput source="locales">
            <TextInput source="title" validate={[required(), maxLength(30)]} />
            <TinymceEditor source="description" defaultValue="" />
            <TextInput source="button_name" />
            <TextInput source="button_url" validate={required()} />
          </TranslatableInput>
        </ArrayInputIterator>
      </ArrayInput>
    </SimpleForm>
  );
};

export const DropHuntingActivitiesCreate = () => {
  return (
    <Create>
      <Form />
    </Create>
  );
};

export const DropHuntingActivitiesEdit = () => {
  return (
    <Edit actions={<EditActions />}>
      <Form />
    </Edit>
  );
};

export const DropHuntingActivitiesList = () => (
  <List
    sort={{ field: 'id', order: SortingDirection.ASC }}
    perPage={50}
    filters={[
      <TextInput label="Name" source="coinName" alwaysOn={true} />,
      <SelectInput source="status" choices={activityStatusTypes} alwaysOn={true} />,
      <ReferenceInput source="rewardId" reference={AppResourceNames.DROP_HUNTING_REWARDS} alwaysOn={true}>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>,
      <BooleanInput source="enabled" alwaysOn={true} parse={(value) => value || ''} />,
    ]}
  >
    <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton disabled={getIsDisabledByRole()} />}>
      <NumberField source="id" />
      <TextField source="coinName" label="Name" sortable={false} />
      <TextField source="status" />
      <TextField source="rewardName" sortable={false} />
      <NumberField source="rating" sortable={false} />
      <ToggleButton source="enabled" />
      <EditButton />
      <AdminDeleteButton />
    </Datagrid>
  </List>
);
